<template>
	<div class="row">
		<div class="col-12">
			<h2>{{ $t('invoice.assignation_transaction') }}</h2>
		</div>

		<div class="col-6">
			{{ date }}
			<p>{{ bridge_transaction.bridgetransaction_description }}</p>
			<h4>{{ amount }}</h4>
			{{ bridge_transaction.bridge_account.bridge_bank.bridgebank_name }} - {{ bridge_transaction.bridge_account.bridgeaccount_name }}<br>
			{{ bridge_transaction.bridge_account.bridgeaccount_iban }}
		</div>
		<div class="col-6">
			<div class="form-group">
				<label for="memo">{{ $t('invoice.memo') }}</label>
				<input name="memo" type="text" class="form-control" v-model="memo">
			</div>
			<div class="form-group">
				<b-form-checkbox name="flux_client" switch v-model="flux_client">{{ $t('invoice.flux_client') }}</b-form-checkbox>
			</div>
			<div class="form-group">
				<label for="search_value">{{ $t('invoice.search_invoice_tiers') }}</label>
				<div class="input-group">
					<input name="search_value" type="text" class="form-control" v-model="search_value">
					<div class="input-group-append">
						<b-button variant="primary" @click="search">{{ $t("global.rechercher") }}</b-button>
					</div>
				</div>
			</div>
			<LoadingSpinner v-if="loading_invoices" />
			<div v-else>
				<label for="invoices">{{ $t('invoice.titre_facture') }}</label>
				<i class="d-block" v-if="invoices.length == 0">{{ $t('invoice.invoices_search_empty') }}</i>
				<b-form-checkbox
					v-for="(invoice, key) in invoices"
					class="custom-control custom-checkbox mt-2"
					v-model="invoice.checked"
					name="invoices"
					:key="key"
				>
					{{ invoice.tiers }}<br>
					<strong>{{ invoice.num }}</strong> - {{ invoice.date }} - {{ invoice.amount }}
				</b-form-checkbox>
			</div>
			<div class="text-center mt-3">
				<b-button variant="primary" :disabled="!can_save" @click="checkForm">{{ $t('invoice.associer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" pulse /></b-button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Invoice from "@/mixins/Invoice.js"
    import Accounting from "@/mixins/Accounting.js"
    import Shutter from "@/mixins/Shutter.js"

	export default {
		name: 'AssignationTransaction',
		mixins: [Invoice, Accounting, Shutter],
		props: ['bridge_transaction'],
		data () {
			return {
				amount: '',
				date: '',
				memo: '',
				flux_client: false,
				search_value: '',
				invoices: [],
				loading_invoices: false,
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading_invoices = true
				// this.flux_client = this.bridge_transaction.bridgetransaction_amout < 0
				this.amount = await this.priceFormat(this.bridge_transaction.bridgetransaction_amount, this.bridge_transaction.bridgetransaction_currency, true)
				this.date = this.formatDate(this.bridge_transaction.bridgetransaction_date)

				const invoices = await this.getPotentialsInvoicesByBridgeTransaction(this.bridge_transaction.bridgetransaction_id)
				for(let i in invoices) {
					invoices[i].amount = await this.priceFormat(invoices[i].amount/100, invoices[i].currency, true)
					invoices[i].date = this.formatDate(invoices[i].date)
					invoices[i].checked = false
				}
				this.invoices = invoices
				this.loading_invoices = false
			},

			async search() {
				this.loading_invoices = true

				const invoices = await this.searchInvoiceBridgeTransaction(this.flux_client, this.search_value);
				for(let i in invoices) {
					invoices[i].amount = await this.priceFormat(invoices[i].amount/100, invoices[i].currency, true)
					invoices[i].date = this.formatDate(invoices[i].date)
					invoices[i].checked = false
				}
				this.invoices = invoices
				this.loading_invoices = false
			},

			async checkForm() {
				this.processing = true
				const invoices = this.invoices.filter(invoice => invoice.checked)
				if(invoices.length == 0) {
					this.processing = false
					return false
				}
				const invoices_ids = invoices.map(invoice => invoice.id)
				await this.assignBridgeTransaction(this.bridge_transaction.bridgetransaction_id, this.flux_client, invoices_ids, this.memo)
				this.processing = false
				this.ok()
				this.shutterPanel().close('assignation-flow-transaction')
			}
		},

		computed: {
			can_save() {
				return this.invoices.filter(invoice => invoice.checked).length > 0
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>